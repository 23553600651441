import React from 'react'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from '@material-ui/core/styles'
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import styles2 from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import GridItem from "components/Grid/GridItem"
import { workoutTypes, workoutTypesBK, workoutLevels, workoutSections } from './workoutTypes';
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';

const useStyles = makeStyles({ ...styles, ...styles2 })


const WorkoutTypeAndLevel = props => {
    const [ user ] = useAtom(userAtom)

    const { workoutLevel, setWorkoutLevel, setWorkoutType, workoutType, workoutSection, setWorkoutSection } = props

    const classes = useStyles()

    const _types = user.username === "SÜPERKOÇ" ? workoutTypesBK : workoutTypes 

    console.log(user)

    return (
        <>
            <GridItem xs={12} md={3}>
                <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                >
                    <InputLabel
                        htmlFor="multiple-select"
                        className={classes.selectLabel}
                    >
                        Antrenman Tipi
                    </InputLabel>
                    <Select
                        value={workoutType}
                        onChange={e => setWorkoutType(e.target.value)}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                            name: "multipleSelect",
                            id: "multiple-select"
                        }}
                    >
                        <MenuItem
                            value=''
                            classes={{
                                root: classes.selectMenuItem
                            }}
                        >
                            Seçiniz
                        </MenuItem>
                        {_types.map(t => {
                            return (
                                <MenuItem
                                    key={t}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={t}
                                >
                                    {t}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} md={3}>
                <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                >
                    <InputLabel
                        htmlFor="multiple-select"
                        className={classes.selectLabel}
                    >
                        Antrenman Derecesi
                    </InputLabel>
                    <Select
                        value={workoutLevel}
                        onChange={e => setWorkoutLevel(e.target.value)}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                            name: "multipleSelect",
                            id: "multiple-select"
                        }}
                    >
                        <MenuItem
                            value=''
                            classes={{
                                root: classes.selectMenuItem
                            }}
                        >
                            Seçiniz
                        </MenuItem>
                        {workoutLevels.map(l => {
                            return (
                                <MenuItem
                                    key={l}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={l}
                                >
                                    {l}
                                </MenuItem>
                            )
                        })
                        }
                    </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} md={3}>
                <FormControl
                    fullWidth
                    className={classes.selectFormControl}
                >
                    <InputLabel
                        htmlFor="multiple-select"
                        className={classes.selectLabel}
                    >
                        Aylar
                    </InputLabel>
                    <Select
                        value={workoutSection}
                        onChange={e => setWorkoutSection(e.target.value)}
                        MenuProps={{ className: classes.selectMenu }}
                        classes={{ select: classes.select }}
                        inputProps={{
                            name: "multipleSelect",
                            id: "multiple-select"
                        }}
                    >
                        <MenuItem
                            value=''
                            classes={{
                                root: classes.selectMenuItem
                            }}
                        >
                            Seçiniz
                        </MenuItem>
                        {workoutSections.map(l => {
                            return (
                                <MenuItem
                                    key={l}
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={l}
                                >
                                    {l}
                                </MenuItem>
                            )
                        })
                        }
                    </Select>
                </FormControl>
            </GridItem>
        </>
    )
}

export default WorkoutTypeAndLevel