import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import React, { useState, useEffect } from 'react'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput';
import Swal from 'sweetalert2';
import { useAtom } from 'jotai';
import { userAtom } from '_atoms/user';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import { makeStyles } from '@material-ui/core/styles'
import { getUserNutritions, createUserNutrition } from '_services/food'
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import Alert from '@material-ui/lab/Alert';
import MealDialog from './MealDialog';
import { updateUserNutrition } from '_services/food';
import ProgramDialog from './ProgramDialog';

const useStyles = makeStyles(styles)

const Nutrition = props => {
    const [user] = useAtom(userAtom)
    const [name, setName] = useState("")
    const [totalCalori, setTotalCalori] = useState("")
    const [meals, setMeals] = useState([])
    const [mealPattern, setMealPattern] = useState([])
    const [mealPatternString, setMealPatternString] = useState('')
    const [startDate] = useState(moment().toDate())
    const [endDate, setEndDate] = useState(moment().add(1, "month"))
    const [ programDialog, setProgramDialog ] = useState(false)
    const [ sablon, setSablon] = useState({})

    const [nutObject, setNutObject] = useState({})
    const [mode, setMode] = useState(0)
    const [id, setId] = useState("")

    const [ mealDialog, setMealDialog ] = useState(false)
    const [ meal, setMeal ] = useState("")
    const [ mealIndex, setMealIndex ] = useState(0)
    const { token } = user

    const { location, customer } = props
    const classes = useStyles()

    const [ms, setMs] = useState([])

    const mealDialogToggle = () => {
        if(mealDialog){
            setMeal("")
            setMealIndex(0)
        }
        setMealDialog(!mealDialog)
    }

    useEffect(() => {
        if (customer && customer !== '') {
            const data = { user: customer._id }
            getUserNutritions(data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setId(res.data.data._id || '')
                        setNutObject(res.data.data || {})
                        setName(res.data.data.name || '')
                        setTotalCalori(res.data.data.totalCalori || '')
                        setMealPattern(res.data.data.mealPattern || [])
                        setMealPatternString(res.data.data.mealPattern && res.data.data.mealPattern.length > 0 ? JSON.stringify({ pattern: res.data.data.mealPattern }) : '')
                        setEndDate(res.data.data.endDate || "")
                        const emptyIndex = meals.indexOf("")
                        let arr = Array.from({ length: res.data.data.mealPattern.length }, (v,) => "")
                        if(emptyIndex > -1 ){
                            setMeals(arr)
                        }else{
                            setMeals(res.data.data.meals || arr )
                        }
                    } else {

                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [customer])

    useEffect(() => {
        if (mealPatternString === "") {
            setMealPattern([])
        } else {
            const pat = JSON.parse(mealPatternString)
            if (pat.pattern) {
                setMealPattern(pat.pattern)
            }
        }
    }, [mealPatternString])

    const handleProgramDialogToggle = () => {
        if(programDialog){

        }
        setProgramDialog(!programDialog)
    }

    const handleSubmit = () => {
        const data = { name, totalCalori, meals, startDate, endDate, mealPattern, user: customer }
        if (!mealPattern || mealPattern.length < 3) {
            Swal.fire({
                icon: "warning",
                title: "Lütfen Öğün Sayısı Seçiniz."
            })
            return
        }
        if( mealPattern.length !== meals.length || meals.length === 0){
            Swal.fire({
                icon: "warning",
                title: "Lütfen öğünleri doldurunuz."
            })
            return
        }
        createUserNutrition(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setId(res.data.data._id)
                    setNutObject(res.data.data)
                    Swal.fire({
                        icon: "success",
                        title: "İşlem Başarılı"
                    })
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Bir Hata Oluştu"
                    })
                }
            })
            .catch(err => {
                Swal.fire({
                    icon: "error",
                    title: "Bir Hata Oluştu!"
                })
            })
    }

    const handleMealSubmit = () => {
        const data = { id, meals, mealPattern }
        updateUserNutrition(token,data)
        .then( res => {
            if (res.data && res.data.code === 200) {
                Swal.fire({
                    icon: "success",
                    title: "İşlem Başarılı"
                })
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Bir Hata Oluştu"
                })
            }
        })
        .catch(err => {
            Swal.fire({
                icon: "error",
                title: "Bir Hata Oluştu!"
            })
        })
    }

    const emptyIndex = meals.indexOf("")

    return (
        <div style={{ overflow: "hidden" }}>
            <GridContainer alignItems='center'>
                <GridItem xs={12}>
                    <GridContainer justify="flex-end">
                        <GridItem xs={6} md={2}>
                            <Button fullWidth color='rose' onClick={handleProgramDialogToggle}>Program Seç</Button>
                        </GridItem>
                        <GridItem xs={6} md={2}>
                            <Button fullWidth color='success' onClick={handleSubmit}>KAYDET</Button>
                        </GridItem>
                    </GridContainer>
                </GridItem>
                <GridItem xs={12} md={4}>
                    <CustomInput
                        labelText='Program Adı'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            value: name,
                            onChange: e => setName(e.target.value)
                        }}
                    />
                </GridItem>
                <GridItem xs={12} md={4}>
                    <CustomInput
                        labelText='Toplam Yaklaşık Kalori'
                        formControlProps={{
                            fullWidth: true,
                        }}
                        inputProps={{
                            value: totalCalori,
                            onChange: e => setTotalCalori(e.target.value),
                            type: 'number'
                        }}
                    />
                </GridItem>
                <GridItem xs={12} md={4}>
                    <DatePicker
                        label="Bitiş Zamanı"
                        value={endDate || moment().format('YYYY-MM-DD')}
                        onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))}
                        format='DD MMMM YYYY'
                        okLabel='TAMAM'
                        cancelLabel='KAPAT'
                        fullWidth
                    />
                </GridItem>
                <GridItem xs={12}>
                </GridItem>
                {   meals.length === mealPattern.length && meals.length > 0
                        ?   <GridItem xs={12}>
                                <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                                    <GridContainer>
                                        <GridItem xs={12} md={emptyIndex === -1 ? 10 : 6}>
                                            <h4>ÖĞÜNLER</h4>
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            </GridItem> 
                    : null

                }

                {mealPattern.length > 0 && mealPattern.map((meal, i) => {
                    return (
                        <GridItem xs={12} md={4} key={meal + i}>
                            <div style={{ marginRight: 20, marginBottom: 20 }}>
                                <Button color={(meals && meals[i] !== "") ? "success" : "rose"} fullWidth
                                    onClick = { () => {
                                        setMeal(meal)
                                        setMealIndex(i)
                                        mealDialogToggle()
                                    }}
                                >{meal}</Button>
                            </div>
                        </GridItem>
                    )
                })}
            </GridContainer>
            <MealDialog
                open = { mealDialog }
                toggle = { mealDialogToggle }
                meal = { meal }
                user = { customer._id }
                setMeals = { setMeals }
                meals = { meals }
                mealIndex = { mealIndex }
                token =  { token }
                sablon = { sablon }
            />
            <ProgramDialog
                open = { programDialog }
                toggle = { handleProgramDialogToggle }
                setMealPattern = { setMealPattern }
                setSablon = { setSablon } 
                setMealPatternString = { setMealPatternString }
                setName = { setName }
                setTotalCalori = {setTotalCalori}
            />
        </div>
    )
}

export default Nutrition