import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useAtom } from 'jotai'
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Button from 'components/CustomButtons/Button'
import React, { useEffect, useState } from 'react'
import { userAtom } from '_atoms/user'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/tr'
import PaginatedGrid from 'components/DataGrid/PaginatedGrid'
import { getCustomersWithPackages } from '_services/customer'
import Buttons from 'views/Components/Buttons'

const image = require('../../assets/img/placeholder.jpg')

const Users = props => {
    const [user] = useAtom(userAtom)
    const [customers, setCustomers] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [ total, setTotal ] = useState(0)
    
    const { token } = user

    useEffect(() => {
        const data = { page : currentPage, pageLength : pageSize, _package : user._package }
        getCustomersWithPackages(token, data)
            .then(res => {
                console.log(res.data)
                if (res.data && res.data.code === 200) {
                    setCustomers(res.data.data)
                    setTotal(res.data.total)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [pageSize, currentPage ])

    const columns = [
        { name: 'avatar', title: "Avatar" },
        { name: 'name', title: "Müşteri Adı" },
        { name: 'package', title: "Paketi" },
        { name: 'packageEnds', title: "Paket Bitiş" },
        { name: 'programEnds', title: "Program Bitiş" },
        { name: 'action', title: "Detay" }
    ];

    const rows = () => {
        return customers.map((p) => ({
            avatar : <img src={p.avatar ? p.avatar : image } alt="user" style={{height : 80, width : 80, margin : 0, objectFit : "cover", borderRadius : 8 }} />,
            name: p.name ? (p.name.first + ' ' + p.name.family) : "",
            package: p._package ? p._package.name : ' - ',
            packageEnds: p.packageValidUntil ? moment(p.packageValidUntil).format("DD MMMM YYYY") : " - ",
            programEnds: (p.currentProgram && p.currentProgram._id) ? moment(p.currentProgram.endDate).format("DD MMMM YYYY") : " - ",
            action: <Link to={{ pathname: "/admin/kullanici-detay", id: p._id, customer : p, program : (p.currentProgram && p.currentProgram._id) ? p.currentProgram._id : "" }}>
                <Button color='primary'>Düzenle</Button>
            </Link>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'avatar', width: 150 },
        { columnName: 'name', width: 200 },
        { columnName: 'packageEnds', width: 200 },
        { columnName: 'programEnds', width: 200 },
        { columnName: 'package', width: 250 },
        { columnName: 'action', width: 150 }
    ];

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <CardIcon color='rose'>
                            <h4 style={{ color: "#fff" }}>Kullanıcılar</h4>
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <PaginatedGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                            currentPage = {currentPage}
                            setCurrentPage = { setCurrentPage }
                            pageSize = { pageSize }
                            setPageSize = { setPageSize }
                            total = { total }
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default Users