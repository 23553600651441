import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { getMuscleGroups, getMovements } from "_services/workout";
import Check from "@material-ui/icons/Check";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Tooltip from "@material-ui/core/Tooltip";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Vimeo from '@u-wave/react-vimeo';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button'

const useStyles = makeStyles((theme) => ({
    ...styles,
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddMovementDialog = (props) => {
    const { open, toggle, movementObjects, setMovementObjects, movementInfo, setMovementInfo, restHours, setRestHours } = props
    const [movementPool, setMovementPool] = useState([])
    const [groups, setGroups] = useState([])
    const [muscleGroups, setMuscleGroups] = useState([])
    const classes = useStyles();
    const [name, setName] = useState("")

    const muscleResolver = groups => {
        let text = ''
        for (let i = 0; i < groups.length; i++) {
            if ((i + 1) === groups.length) {
                text = text + ' ' + groups[i].name
            } else {
                text = text + groups[i].name + ', '
            }
        }
        return text
    }

    useEffect(() => {
        const data = { isActive: true }
        if (open && groups.length === 0) {
            getMuscleGroups(data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setGroups(res.data.data)
                    }
                    else {
                        console.log(res.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [open])

    const handleClose = () => {
        toggle();
    };

    const handleGroupId = id => {
        const index = muscleGroups.indexOf(id)
        if (index > -1) {
            setMuscleGroups([
                ...muscleGroups.slice(0, index),
                ...muscleGroups.slice(index + 1)
            ])
        } else {
            setMuscleGroups([
                ...muscleGroups,
                id
            ])
        }
    }

    useEffect(() => {
        if (open && movementPool.length === 0) {
            const data = { isActive: true }
            getMovements(data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setMovementPool(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [open])

    const handleAddMovement = mov => {
        if (movementObjects.length > 19) {
            alert("Bir antrenmana en fazla 20 hareket ekleyebilirsiniz.")
            return
        }
        const index = movementObjects.map(m => m._id).indexOf(mov._id)
        if (index > -1) {
            alert(mov.name + " hareketi zaten eklenmiş.")
        } else {
            setMovementObjects([
                ...movementObjects,
                mov
            ])
            setMovementInfo([
                ...movementInfo,
                { weight: "Bilinmiyor", repeat: "12", sets: "3" }
            ])
        }
    }

    const handleRemoveMovement = index => {
        setMovementObjects([
            ...movementObjects.slice(0, index),
            ...movementObjects.slice(index + 1)
        ])
        setMovementInfo([
            ...movementInfo.slice(0, index),
            ...movementInfo.slice(index + 1)
        ])
    }

    let filteredPool = movementPool

    if (muscleGroups.length > 0) {
        for (let i = 0; i < muscleGroups.length; i++) {
            filteredPool = filteredPool.filter(m => m.muscleGroups.map(a => a._id).includes(muscleGroups[i]))
        }
    }

    filteredPool = filteredPool.filter(m => m.name.toLowerCase().trim().includes(name.toLowerCase().trim()))

    return (
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar} color='primary'>
                <Toolbar color='primary'>
                    <Typography variant="h6" className={classes.title}>
                        Hareketler
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div style={{ width: "99%" }}>
                <GridContainer justify='center'>
                    <GridItem xs={11}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <GridContainer>
                                    <GridItem xs={12} md={4}>
                                        <h6>Hareket Adı</h6>
                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText='Hareket Adı'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: name,
                                                    onChange: (e) => setName(e.target.value),
                                                    type: 'text',
                                                }}
                                            />
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} md={4}>
                                        <h6>Dinlenme Süresi</h6>
                                        <GridItem xs={12}>
                                            <CustomInput
                                                labelText='Dinlenme Süresi'
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                                inputProps={{
                                                    value: restHours,
                                                    onChange: (e) => setRestHours(e.target.value),
                                                    type: 'number',
                                                }}
                                            />
                                        </GridItem>
                                    </GridItem>
                                    <GridItem xs={12} md={12}>
                                        <h6>Kas Grupları</h6>
                                        <GridContainer>
                                            {groups.map(g => {
                                                return (
                                                    <GridItem key={g._id}>
                                                        <div className={classes.checkboxAndRadio}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        tabIndex={-1}
                                                                        onClick={() => handleGroupId(g._id)}
                                                                        checked={muscleGroups.includes(g._id)}
                                                                        checkedIcon={<Check className={classes.checkedIcon} />}
                                                                        icon={<Check className={classes.uncheckedIcon} />}
                                                                        classes={{
                                                                            checked: classes.checked,
                                                                            root: classes.checkRoot
                                                                        }}
                                                                    />
                                                                }
                                                                classes={{
                                                                    label: classes.label,
                                                                    root: classes.labelRoot
                                                                }}
                                                                label={g.name}
                                                            />
                                                        </div>
                                                    </GridItem>
                                                )
                                            })}
                                        </GridContainer>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} md={6} style={{ marginTop: 30 }}>
                                <h4>Kayıtlı Hareketler</h4>
                                {filteredPool.length === 0 &&
                                    <p style={{ fontSize: 16, fontWeight: 300 }}>Arama yapmadınız ya da aradığınız kriterde hareket bulunamadı.</p>
                                }
                                {muscleGroups.length === 0 &&
                                    <p style={{ fontSize: 16, fontWeight: 300 }}>En az bir kas grubu seçiniz.</p>
                                }
                                <GridContainer>
                                    {muscleGroups.length > 0 && filteredPool.map(mov => {
                                        return (
                                            <GridItem xs={12} key={mov._id} style={{ border: "1px solid lightgray", borderRadius: 10 }}>
                                                <GridContainer>
                                                    <GridItem xs={3}>
                                                        <Vimeo
                                                            video={mov.videoId}
                                                            width={150}
                                                            height={150}
                                                            autopause
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={7} style={{ height: "100%", margin: "auto" }}>
                                                        <h5>{mov.name}</h5>
                                                        {mov.description}
                                                        {muscleResolver(mov.muscleGroups)}
                                                    </GridItem>
                                                    <GridItem xs={2} style={{ height: "100%", margin: "auto" }}>
                                                        <Button color='primary' onClick={() => handleAddMovement(mov)}>
                                                            EKLE
                                                        </Button> 
                                                        {/* <Tooltip title='Ekle'>
                                                            <IconButton onClick={() => handleAddMovement(mov)}>
                                                                <AddCircleOutlineIcon color='primary' size={30} />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        )
                                    })}
                                </GridContainer>

                            </GridItem>
                            <GridItem xs={12} md={6} style={{ marginTop: 30 }}>
                                <h4>Eklenen Hareketler</h4>
                                {movementObjects.length === 0 &&
                                    <p style={{ fontSize: 16, fontWeight: 300 }}>Hareket eklemediniz.</p>
                                }
                                <GridContainer>
                                    {movementObjects.map((mov, i) => {
                                        return (
                                            <GridItem xs={12} key={mov._id} style={{ border: "1px solid lightgray", borderRadius: 10 }}>
                                                <GridContainer>
                                                    <GridItem xs={3}>
                                                        <Vimeo
                                                            video={mov.videoId}
                                                            width={150}
                                                            height={150}
                                                            autopause
                                                        />
                                                    </GridItem>
                                                    <GridItem xs={7} style={{ height: "100%", margin: "auto" }}>
                                                        <h5>{mov.name}</h5>
                                                        {mov.description}
                                                        <GridContainer>
                                                            <GridItem xs={4}>
                                                                <CustomInput
                                                                    labelText='Ağırlık - Seviye'
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        value: movementInfo[i] ? movementInfo[i].weight : "",
                                                                        onChange: (e) => {
                                                                            setMovementInfo([
                                                                                ...movementInfo.slice(0, i),
                                                                                { ...movementInfo[i], weight: e.target.value },
                                                                                ...movementInfo.slice(i + 1)
                                                                            ])
                                                                        },
                                                                        type: 'text',
                                                                    }}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={4}>
                                                                <CustomInput
                                                                    labelText='Tekrar Sayısı - Süre'
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        value: movementInfo[i] ? movementInfo[i].repeat : "",
                                                                        onChange: (e) => {
                                                                            setMovementInfo([
                                                                                ...movementInfo.slice(0, i),
                                                                                { ...movementInfo[i], repeat: e.target.value },
                                                                                ...movementInfo.slice(i + 1)
                                                                            ])
                                                                        },
                                                                        type: 'text',
                                                                    }}
                                                                />
                                                            </GridItem>
                                                            <GridItem xs={4}>
                                                                <CustomInput
                                                                    labelText='Set Sayısı'
                                                                    formControlProps={{
                                                                        fullWidth: true,
                                                                    }}
                                                                    inputProps={{
                                                                        value: movementInfo[i] ? movementInfo[i].sets : "",
                                                                        onChange: (e) => {
                                                                            setMovementInfo([
                                                                                ...movementInfo.slice(0, i),
                                                                                { ...movementInfo[i], sets: e.target.value },
                                                                                ...movementInfo.slice(i + 1)
                                                                            ])
                                                                        },
                                                                        type: 'text',
                                                                    }}
                                                                />
                                                            </GridItem>
                                                        </GridContainer>
                                                    </GridItem>
                                                    <GridItem xs={2} style={{ height: "100%", margin: "auto" }}>
                                                        <Button color='primary' onClick={() => handleRemoveMovement(i)}>
                                                            Çıkar
                                                        </Button> 
                                                        {/* <Tooltip title='Çıkar'>
                                                            <IconButton onClick={() => handleRemoveMovement(i)}>
                                                                <HighlightOffIcon color='primary' size={30} />
                                                            </IconButton>
                                                        </Tooltip> */}
                                                    </GridItem>
                                                </GridContainer>
                                            </GridItem>
                                        )
                                    })}
                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>
    );
}

export default AddMovementDialog
