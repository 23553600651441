import React, { useEffect, useState } from "react"
import { makeStyles } from '@material-ui/core/styles'
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardIcon from "components/Card/CardIcon"
import CardBody from "components/Card/CardBody"
import DataGrid from 'components/DataGrid/SimpleGrid'
import Button from 'components/CustomButtons/Button'
import { Link } from 'react-router-dom'
import { getWorkouts } from "_services/workout"
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Vimeo from '@u-wave/react-vimeo';
import { workoutLevels, workoutSections, workoutTypes } from "./workoutTypes"

const styles = {

}

const useStyles = makeStyles(styles)

const WorkOuts = props => {
    const [workouts, setWorkouts] = useState([])
    const [ filter, setFilter ] = useState("Cardio")
    const [ section, setSection ] = useState("")
    const [ level, setLevel ] = useState("")
    const classes = useStyles()

    const columns = [
        { name: 'name', title: "Antrenman Adı" },
        { name: 'workoutType', title: "Tipi" },
        { name: 'workoutLevel', title: "Seviye" },
        { name: 'workoutSections', title: "Aylar" },
        { name: 'movements', title: "Hareket Sayısı" },
        { name: 'action', title: "İşlem" }
    ];

    const rows = () => {
        return workouts.map((p) => ({
            name: p.name || '',
            workoutType: p.workoutType || "",
            workoutLevel: p.workoutLevel,
            workoutSections: p.workoutSection,
            movements: p.movements.length,
            action: <Link to={{ pathname: "/admin/antrenman-duzenle", workout: p }}>
                <Tooltip title='Düzenle'>
                    <IconButton color='primary' >
                        <EditIcon />
                    </IconButton>
                </Tooltip>
            </Link>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 600 },
        { columnName: 'movements', width: 200 },
        { columnName: 'workoutType', width: 200 },
        { columnName: 'workoutLevel', width: 200 },
        { columnName: 'workoutSections', width: 200 },
        { columnName: 'action', width: 150 }
    ];

    useEffect(() => {
        let data = { isActive: true, workoutType : filter }
        if(section !== ""){
            data = { ...data, workoutSection : section }
        }
        if(level !== ""){
            data = { ...data, workoutLevel : level }
        }
        getWorkouts(data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setWorkouts(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [filter, section, level])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <GridContainer>
                            <GridItem xs={12} md={10}>
                                <CardIcon color='rose'>
                                    <h4 style={{ color: "#fff" }}> Antrenmanlar </h4>
                                </CardIcon>
                            </GridItem>
                            <GridItem xs={12} md={2}>
                                <Link to={{ pathname: "/admin/antrenman-duzenle" }}>
                                    <Button color='success' block>Ekle</Button>
                                </Link>
                            </GridItem>
                            <GridItem xs={12}>
                                <Button color={filter === "" ? 'success' : "info"} onClick={() => setFilter("")} style={{ marginRight: 10, marginBottom: 5 }} >Seçilmemiş</Button>
                                {workoutTypes.map(m => {
                                    return (
                                        <Button color={filter === m ? 'success' : "info"} key={m} onClick={() => setFilter(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                                    )
                                })}
                            </GridItem>
                            <GridItem xs={12}>
                                <h5>Aylar</h5>
                            </GridItem>
                            <GridItem xs={12}>
                                <Button color={section === "" ? 'success' : "info"} onClick={() => setSection("")} style={{ marginRight: 10, marginBottom: 5 }} >Tümü</Button>
                                {workoutSections.map(m => {
                                    return (
                                        <Button color={section === m ? 'success' : "info"} key={m} onClick={() => setSection(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                                    )
                                })}
                            </GridItem>
                            <GridItem xs={12}>
                                <h5>Seviyeler</h5>
                            </GridItem>
                            <GridItem xs={12}>
                                <Button color={level === "" ? 'success' : "info"} onClick={() => setLevel("")} style={{ marginRight: 10, marginBottom: 5 }} >Tümü</Button>
                                {workoutLevels.map(m => {
                                    return (
                                        <Button color={level === m ? 'success' : "info"} key={m} onClick={() => setLevel(m)} style={{ marginRight: 10, marginBottom: 5 }} >{m}</Button>
                                    )
                                })}
                            </GridItem>
                        </GridContainer>
                    </CardHeader>
                    <CardBody>
                        <DataGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default WorkOuts