import axios from 'axios';
import { domain } from './domain';

export const getCustomers = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/cgetusers', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCustomersWithPackages = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/cgetuserswithpackage', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCustomerById = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/cgetuserdetails', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getCustomerMeasures = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Authorization' : 'Bearer ' + token,
            'Content-type': 'application/json' 
        },
        data,
    };
    return axios(domain + '/getusermeasures', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};