import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import DataGrid from 'components/DataGrid/SimpleGrid'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { useAtom } from 'jotai'
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import React, { useEffect, useState } from 'react'
import { userAtom } from '_atoms/user'
import moment from 'moment'
import 'moment/locale/tr'
import { getQuestions } from '_services/questions'
import QuestionDialog from './QuestionDialog'


const Questions = props => {
    const [user] = useAtom(userAtom)
    const [questions, setQuestions] = useState([])
    const [openQuestionDialog, setOpenQuestionDialog] = useState(false)
    const [questionId, setQuestionId] = useState("")
    const { token } = user

    const { questionEnum } = user

    const handleQuestionDialogToggle = (id) => {
        if (id) {
            setQuestionId(id)
        } else {
            setQuestionId("")
        }
        setOpenQuestionDialog(!openQuestionDialog)
    }

    useEffect(() => {
        const data = { questionEnum }
        getQuestions(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setQuestions(res.data.data)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const enums = index => {
        switch (index) {
            case 0:
                return "Genel Soru"
            case 1:
                return "Bilgehan Koç'a Sor"
            case 2:
                return "Mpfit Uzmanına Sor"
            case 3:
                return "Spor Beslenme Uzmanına Sor"
            case 4:
                return "Anabolik Uzmanına Sor"
            case 5:
                return "Doktora Sor"
            case 6:
                return "Spor Psikoloğuna Sor"
            default:
                return
        }
    }

    const columns = [
        { name: 'name', title: "Müşteri Adı" },
        { name: 'title', title: "Soru Başlığı" },
        { name: 'enum', title: "Soru Tipi" },
        { name: 'created', title: "Mesaj Zamanı" },
        { name: 'action', title: "Detay" }
    ];

    const rows = () => {
        return questions.map((p) => ({
            name: p.user && p.user.name ? (p.user.name.first + ' ' + p.user.name.family) : "",
            title: p.title,
            created: p.lastUpdated ? moment(p.lastUpdated).format("DD MMMM YYYY HH:mm") : " - ",
            enum: enums(p.questionEnum),
            action: <Tooltip title='Detay'>
                <IconButton color='primary' onClick={() => handleQuestionDialogToggle(p._id)} >
                    <EditIcon />
                </IconButton>
            </Tooltip>
        }));
    };

    const defaultColumnWidths = [
        { columnName: 'name', width: 200 },
        { columnName: 'title', width: 300 },
        { columnName: 'created', width: 200 },
        { columnName: 'enum', width: 200 },
        { columnName: 'action', width: 150 }
    ];

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <CardIcon color='rose'>
                            <h4 style={{ color: "#fff" }}>Sorular</h4>
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <DataGrid
                            rows={rows()}
                            columns={columns}
                            defaultColumnWidths={defaultColumnWidths}
                        />
                    </CardBody>
                </Card>
            </GridItem>
            <QuestionDialog
                open={openQuestionDialog}
                toggle={handleQuestionDialogToggle}
                id={questionId}
                token={token}
            />
        </GridContainer>
    )
}

export default Questions