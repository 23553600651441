import React, { useEffect, useState } from 'react'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import NavPills from "components/NavPills/NavPills.js";
import GeneralInfo from './GeneralInfo'
import Workouts from './Workouts'
import Nutrition from './Nutrition'
import Measurements from './Measurements'
import { useAtom } from 'jotai'
import { userAtom } from '_atoms/user'
import Sorular from './Sorular'

const User = props => {
    const [user] = useAtom(userAtom)
    const [id, setId] = useState('')
    const [customer, setCustomer] = useState({})
    const { location } = props
    const { token } = user

    useEffect(() => {
        if (location && location.id) {
            setId(location.id)
        }
        if (location && location.customer && location.customer._id) {
            setCustomer(location.customer)
        }
    }, [location])

    return (
        <GridContainer>
            <GridItem xs={12}>
                <Card>
                    <CardHeader>
                        <CardIcon color='rose'>
                            <h4 style={{ color: "#fff" }}> Kullanıcı Detayı</h4>
                        </CardIcon>
                    </CardHeader>
                    <CardBody>
                        <NavPills
                            color="rose"
                            tabs={[
                                {
                                    tabButton: "Genel Bilgiler",
                                    tabContent: <GeneralInfo id={id} token={token} customer = {customer} />
                                },
                                {
                                    tabButton: "Programlar",
                                    tabContent: <Workouts id={id} token={token} customer = {customer} setCustomer={setCustomer} />
                                },
                                {
                                    tabButton: "Beslenme",
                                    tabContent: <Nutrition id={id} token={token} customer = {customer} />
                                },
                                {
                                    tabButton: "Ölçümler",
                                    tabContent: <Measurements id={id} token={token} />
                                },
                                {
                                    tabButton: "Sorular",
                                    tabContent: <Sorular id={id} token={token} customer = {customer} />
                                }
                            ]}
                        />
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    )
}

export default User