import Dashboard from "views/Dashboard/Dashboard.js";
import Anasayfa from "views/Dashboard/Anasayfa.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import TimelinePage from "views/Pages/Timeline.js";
import UserProfile from "views/Pages/UserProfile.js";

// Inner Pages
import MuscleGroups from 'views/Workouts/MuscleGroups'
import Movements from 'views/Workouts/Movements'
import Workouts from 'views/Workouts/Workouts'
import Workout from 'views/Workouts/Workout'
import FreeWorkout from 'views/Workouts/FreeWorkout'
import MuscleGroup from 'views/Workouts/MuscleGroup'
import Movement from 'views/Workouts/Movement'
import Users from "views/Users/Users";
import User from "views/Users/User";
import Subscriptions from 'views/Home/Subscriptions'
import Subscription from 'views/Home/Subscription'
import NutritionTable from 'views/Nutrition/NutritionTable'

// @material-ui/icons
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import DashboardIcon from "@material-ui/icons/SupervisedUserCircle";
import QuestionIcon from "@material-ui/icons/QuestionAnswer"
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import ReceiptIcon from '@material-ui/icons/Receipt';
import StoreIcon from '@material-ui/icons/Store'
import Nutritions from "views/Nutrition/Nutritions";
import Nutrition from "views/Nutrition/Nutrition";
import Blogs from "views/Blog/Blogs";
import Blog from "views/Blog/Blog";
import Meals from "views/Nutrition/Meals";
import Meal from "views/Nutrition/Meal";
import Agreements from "views/Agreements/Agreements";
import Agreement from "views/Agreements/Agreement";
import Questions from "views/Questions/Questions";
import AllUsers from "views/Users/AllUsers";
import Coaches from "views/Coach/Coaches";
import Coach from "views/Coach/Coach";

var dashRoutes = [
  // {
  //   collapse: true,
  //   name: "Anasayfa",
  //   icon: DashboardIcon,
  //   state: "dahboardCollapse",
  //   views: [
  //     {
  //       path: "/dashboard",
  //       name: "Anasayfa",
  //       mini: "SL",
  //       component: Anasayfa,
  //       layout: "/admin"
  //     },
  //     {
  //       name: "Blog Detay",
  //       icon: DashboardIcon,
  //       path: "/blog-detay",
  //       mini: "KU",
  //       component: Blog,
  //       layout: "/admin",
  //       hidden: true
  //     },
  //     {
  //       name: "Sözleşme Detay",
  //       icon: DashboardIcon,
  //       path: "/sozlesme-detay",
  //       mini: "KU",
  //       component: Agreement,
  //       layout: "/admin",
  //       hidden: true
  //     },
  //   ]
  // },
  {
    collapse: true,
    name: "Kullanıcılar",
    icon: DashboardIcon,
    state: "usersCollapse",
    views: [
      {
        path: "/dashboard",
        name: "Anasayfa",
        mini: "SL",
        component: Users,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/aktif-kullanicilar",
        name: "Aktif Kullanıcılar",
        mini: "KU",
        component: Users,
        layout: "/admin"
      },
      {
        path: "/tum-kullanicilar",
        name: "Tüm Kullanıcılar",
        mini: "KU",
        component: AllUsers,
        layout: "/admin"
      },
      {
        path: "/kullanici-detay",
        name: "Kullanıcı Detay",
        mini: "KG",
        component: User,
        layout: "/admin",
        hidden: true
      },
      {
        name: "Sözleşme Detay",
        icon: DashboardIcon,
        path: "/sozlesme-detay",
        mini: "KU",
        component: Agreement,
        layout: "/admin",
        hidden: true
      },
      {
        path: "/koc-detay",
        name: "Koç Detay",
        mini: "KG",
        component: Coach,
        layout: "/admin",
        hidden: true
      },
    ]
  },
  {
    collapse: false,
    name: "Sorular",
    icon: QuestionIcon,
    path: "/sorular",
    mini: "SO",
    component: Questions,
    layout: "/admin"
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: StoreIcon,
    state: "pageCollapse",
    hidden: true,
    views: [
      {
        path: "/pricing-page",
        name: "Pricing Page",
        rtlName: "عالتسعير",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth"
      },
      {
        path: "/rtl-support-page",
        name: "RTL Support",
        rtlName: "صودعم رتل",
        mini: "RS",
        rtlMini: "صو",
        component: RTLSupport,
        layout: "/rtl"
      },
      {
        path: "/timeline-page",
        name: "Timeline Page",
        rtlName: "تيالجدول الزمني",
        mini: "T",
        rtlMini: "تي",
        component: TimelinePage,
        layout: "/admin"
      },
      {
        path: "/login-page",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth"
      },
      {
        path: "/register-page",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth"
      },
      {
        path: "/lock-screen-page",
        name: "Lock Screen Page",
        rtlName: "اقفل الشاشة",
        mini: "LS",
        rtlMini: "هذاع",
        component: LockScreenPage,
        layout: "/auth"
      },
      {
        path: "/user-page",
        name: "User Profile",
        rtlName: "ملف تعريفي للمستخدم",
        mini: "UP",
        rtlMini: "شع",
        component: UserProfile,
        layout: "/admin"
      },
      {
        path: "/error-page",
        name: "Error Page",
        rtlName: "صفحة الخطأ",
        mini: "E",
        rtlMini: "البريد",
        component: ErrorPage,
        layout: "/auth"
      }
    ]
  },
];
export default dashRoutes;
