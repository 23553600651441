export const workoutTypesBK = [
    "BK Cardio",
    "BK Cardio + Fitness",
    "BK Adaptasyon",
    "BK Bölgesel",
    "BK Profesyonel Sporcu",
    "BK Podyum Sporcu",
    "BK Ev Antrenmanı",
    "BK Crossfit",
]

export const workoutTypes = [
    "Cardio",
    "Cardio + Fitness",
    "Adaptasyon",
    "Bölgesel",
    "Profesyonel Sporcu",
    "Podyum Sporcu",
    "Ev Antrenmanı",
    "Crossfit"
]

export const workoutLevels = [
    "Başlangıç",
    "Normal",
    "Zor"
]

export const workoutSections = [
    "1. Ay",
    "2. Ay",
    "3. Ay",
    "4. Ay",
    "5. Ay",
    "6. Ay",
    "7. Ay",
    "8. Ay",
    "9. Ay",
    "10. Ay",
    "11. Ay",
    "12. Ay"
]