import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  GroupingState,
  IntegratedGrouping,
  SearchState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  VirtualTable,
  TableHeaderRow,
  PagingPanel,
  TableGroupRow,
  GroupingPanel,
  DragDropProvider,
  Toolbar,
  SearchPanel,
  ColumnChooser,
  TableColumnVisibility,
  TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';

const groupingPanelMessages = {
  groupByColumn: "Gruplamak istediğiniz kolonu bu alana sürükleyip bırakınız."
}

const tableMessages = {
  noData: 'Gösterilecek veri yok',
};

const filterRowMessages = {
  filterPlaceholder: 'Filtrele...',
};

const searchPanelMessages = {
  searchPlaceholder : "Arama"
}

const pagingPanelMessages = {
  showAll: 'Tümü',
  rowsPerPage: 'Sayfadaki kayıt sayısı',
  info: '{count} kayıttan {from} - {to} gösteriliyor.',
}

const DataGrid = (props) => {
  const { columns, rows, defaultColumnWidths, page, setPage } = props


  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [pageSizes] = useState([10, 15, 20, 30, 50]);
  const [grouping] = useState([]);
  const [groupingStateColumnExtensions] = useState([]);
  const [defaultHiddenColumnNames] = useState([]);
  const [columnWidths, setColumnWidths] = useState(defaultColumnWidths);

  const pageAtom = page ? page : currentPage
  const setPageAtom = setPage ? setPage : setCurrentPage

  return (
    <Grid
      rows={rows}
      columns={columns}
    >
      <SortingState
        defaultSorting={[]}
      />
      <DragDropProvider />
      <GroupingState
        defaultGrouping={grouping}
        columnExtensions={groupingStateColumnExtensions}
      />
      <IntegratedSorting />
      <SearchState defaultValue="" />
      <IntegratedFiltering />
      <PagingState
        currentPage={pageAtom}
        onCurrentPageChange={setPageAtom}
        pageSize={pageSize}
        onPageSizeChange={setPageSize}
        messages={pagingPanelMessages}
      />
      <IntegratedPaging />
      <IntegratedGrouping />
      <Table
        messages={tableMessages}
      />
      <TableColumnResizing
        columnWidths={columnWidths}
        onColumnWidthsChange={setColumnWidths}
      />
      <TableHeaderRow
        showSortingControls 
      />
      <TableColumnVisibility
        defaultHiddenColumnNames={defaultHiddenColumnNames}
      />
      <TableGroupRow />
      <Toolbar />
      <SearchPanel 
        messages = {searchPanelMessages}
      />
      <GroupingPanel
        showGroupingControls
        messages={groupingPanelMessages}
      />
      <ColumnChooser />
      <PagingPanel
        pageSizes={pageSizes}
        messages={pagingPanelMessages}
      />
    </Grid>
  );
}

export default DataGrid