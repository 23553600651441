import { useAtom } from "jotai";
import moment from "moment";
import { useState, useEffect } from "react";
import { audioAtom } from "_atoms/audioAtom";
import { deleteAudio } from "../handlers/recordings-list";

export default function useRecordingsList(audio) {
  const [recordings, setRecordings] = useState([]);
  const [, setAud ] = useAtom(audioAtom)

  useEffect(() => {
    if (audio) {
      setAud(audio)
      setRecordings([...recordings, { key: moment().unix(), audio }]);
    }
  }, [audio]);

  return {
    recordings,
    deleteAudio: (audioKey) => deleteAudio(audioKey, setRecordings),
  };
}