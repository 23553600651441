import React, { useState, useEffect } from 'react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import moment from 'moment'
import 'moment/locale/tr'
import { MessageList, Input } from 'react-chat-elements'
import { getConservation } from '_services/questions'
import { updateQuestion } from '_services/questions'
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button'
import { getUserQuestions } from '_services/questions'


const Sorular = props => {
    const [questions, setQuestions] = useState([])
    const [conversation, setConversation] = useState([])
    const [questionId, setQuestionId] = useState("")
    const [messages, setMessages] = useState([])
    const [text, setText] = useState("")
    const [questionEnum, setQuestionEnum] = useState(0)
    const [userId, setUserId] = useState("")

    const { token } = props

    useEffect(() => {
        let messages = conversation.map(c => ({ type: 'text', text: c.text, date: moment(c.created).toDate(), position: c.isUser ? "left" : "right" }))
        messages = messages.sort((a, b) => a.date < b.date ? -1 : 1)
        setMessages(messages)
    }, [conversation])

    useEffect(() => {
        if (questionId !== "") {
            const data = { id: questionId }
            getConservation(token, data)
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setConversation(res.data.data.conversation)
                        setQuestionEnum(res.data.data.questionEnum || 0)
                        setUserId(res.data.data.user)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [questionId])

    const handleSendMessage = () => {
        if (text === "") {
            return
        }
        let created = Date.now()
        let isUser = false
        let isAnswered = true
        let isSeenByUser = false
        const newConversation = [
            ...conversation,
            { text, created, isUser, isAnswered, isSeenByUser }
        ]
        const data = { id : questionId, conversation: newConversation, text: text, }
        updateQuestion(token, data)
            .then(res => {
                if (res.data && res.data.code === 200) {
                    setConversation(res.data.data.conversation)
                    setText("")
                }
            })
            .catch(err => {
                console.log("err")
            })
    }

    useEffect(() => {
        if(props.customer && props.customer._id){
            const data = { user : props.customer._id }
            getUserQuestions( token, data )
                .then(res => {
                    if (res.data && res.data.code === 200) {
                        setQuestions(res.data.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [props.customer])

    const enums = index => {
        switch (index) {
            case 0:
                return "Genel Soru"
            case 1:
                return "Bilgehan Koç'a Sor"
            case 2:
                return "Mpfit Uzmanına Sor"
            case 3:
                return "Spor Beslenme Uzmanına Sor"
            case 4:
                return "Anabolik Uzmanına Sor"
            case 5:
                return "Doktora Sor"
            case 6:
                return "Spor Psikoloğuna Sor"
            default:
                return
        }
    }

    return (
        <GridContainer>
            <GridItem xs={3}>
                <div style={{ border: "1px solid lightgray", padding: 10 }}>
                    {questions.map(p => {
                        return (
                            <div onClick={() => setQuestionId(p._id)} key={p._id} style={{ borderBottom: "1px solid lightgray", padding: 10, marginBottom: 10, cursor: "pointer" }}>
                                <h4 style={{ margin: 0, fontWeight: "500", fontSize: 18 }}>{p.user && p.user.name ? (p.user.name.first + ' ' + p.user.name.family) : ""}</h4>
                                <p style={{ margin: 0, fontWeight: "500", fontSize: 14 }}>{p.title || ""} - {enums(p.questionEnum)}</p>
                                <p style={{ fontWeight: "500", color: "gray", margin: 0 }}>{p.lastUpdated ? moment(p.lastUpdated).format("DD MMMM YYYY HH:mm") : " - "}</p>
                            </div>
                        )
                    })}
                </div>
            </GridItem>
            <GridItem xs={9}>
                <MessageList
                    className='message-list'
                    lockable={true}
                    toBottomHeight={'100%'}
                    dataSource={messages}
                />
                <CustomInput
                    labelText='Mesajınız...'
                    formControlProps={{
                        fullWidth: true,
                    }}
                    inputProps={{
                        value: text,
                        onChange: (e) => setText(e.target.value),
                        onKeyPress: e => {
                            const { key } = e
                            if (key === "Enter") {
                                handleSendMessage()
                            }
                        },
                        endAdornment: (
                            <Button onClick={handleSendMessage} color='rose'>Yolla</Button>
                        ),
                    }}
                />
            </GridItem>
        </GridContainer>
    )
}

export default Sorular