import axios from 'axios';
import { domain } from './domain';

export const createFitnessPackage = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/package', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const updateFitnessPackage = (token, data) => {
    const requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/package', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const getFitnessPackages = (token) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-type': 'application/json',
        },
    };
    return axios(domain + '/package', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};

export const addBanner = (token, data) => {
    const requestOptions = {
        method: 'POST',
        headers: { 
            'Content-type': 'application/json',
            Authorization: 'Bearer ' + token,
        },
        data
    };
    return axios(domain + '/packagebanner', requestOptions)
        .then((response) => {
            if (response.data.code) {
                return response;
            } else {
                throw new Error('Bağlantı hatası!');
            }
        })
        .catch((error) => {
            return error;
        });
};